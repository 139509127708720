<template>
  <div class="about svg-bg2" :class="scrolledTo ? 'animated' : ''">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" data-aos="fade-left">
          <div
            class="d-flex flex-column msg w-100 h-100 align-items-center justify-content-center"
          >
            <h2 class="m-auto">الرسالة</h2>
            <p class="">
              نقدم كل ما يخدم عمليتي التعليم والبحث العلمي ويخلق الشغف بهما. نشارك في جمع
              المحتوى العلمي وتطويره ونقدمه بأسلوب حديث وتصاميم مبتكرة وجذابة
            </p>
          </div>
        </b-col>

        <b-col cols="12" md="6" data-aos="fade-right">
          <div class="w-100 d-flex justify-content-center">
            <svg
              version="1.1"
              height="600"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 595.28 841.89"
              style="enable-background: new 0 0 595.28 841.89"
              xml:space="preserve"
            >
              <path
                class="st1"
                d="M321.24,203.46c22.68,31.93,59.2,106.46,42.4,241.74c0,0-56.9-16.04-117.28,0c0,0-25.77-144.61,43.2-241.72"
              />
              <path
                class="st1"
                d="M292.93,195.81c3.72-4.35,7.53-8.65,11.43-12.9c0,0,5.04,4.27,13.33,13.3"
              />
              <path
                class="st1"
                d="M243.36,480.35c0,0-8.15-26.26-8.83-86.49l-4.57,3.75c-27.78,22.76-63.08,34.28-98.94,32.28l-8.56-0.48v50.94
	H243.36z"
              />
              <path
                class="st1"
                d="M365.13,480.35c0,0,8.15-26.26,8.83-86.49l4.57,3.75c27.78,22.76,63.08,34.28,98.94,32.28l8.56-0.48v50.94
	H365.13z"
              />
              <path
                class="st0"
                d="M325.3,564.53c0,0-0.21,40.92-20.45,55.26c0,0-19.27-9.41-21.05-55.26c0,0-17.28,65.27,21.05,85.02
	C304.85,649.55,344.94,630.54,325.3,564.53z"
              />
              <path
                class="st0"
                d="M312.36,559.34l-2.71,0.7c-3.63,0.94-7.36,0.92-10.98-0.06l-2.38-0.65l7.95,10.37L312.36,559.34z"
              />
              <path
                class="st0"
                d="M287.78,559.34c0,0,16.13,41.78,33.96,0c0,0,2.15,31.07-16.92,46.69C304.81,606.04,288.8,596.62,287.78,559.34z
	"
              />
              <path
                class="st1"
                d="M260.41,540.4l2.04,18.94h85.78l2.16-18.43C350.39,540.91,304.84,558.56,260.41,540.4z"
              />
              <path
                class="st12"
                d="M317.69,196.21c-3.81-4.36-9.33-9.06-13.33-13.3v12.9"
              />
              <path
                class="st12"
                d="M365.13,480.35c0,0,8.15-26.26,8.83-86.49l4.57,3.75c27.78,22.76,63.08,34.28,98.94,32.28l8.56-0.48v50.94
	H365.13z"
              />
              <path
                class="st12"
                d="M305.4,559.34h42.83l2.16-18.43c0,0-19.28,7.47-44.99,7.69V559.34z"
              />
              <path
                class="st13"
                d="M304.85,619.79c20.25-14.35,20.45-55.26,20.45-55.26c19.63,66.01-20.45,85.02-20.45,85.02"
              />
              <path
                class="st13"
                d="M304.23,569.71l8.13-10.37l-2.71,0.7c-1.76,0.46-3.54,0.69-5.33,0.69"
              />
              <path
                class="st13"
                d="M304.54,577.9c5.14,0.29,11.07-4.19,17.2-18.56c0,0,2.15,31.07-16.92,46.69"
              />
              <g>
                <path
                  class="st0"
                  d="M304.85,649.45c-6.18-0.32-12.38-0.3-18.5,0.29c-3.05,0.32-6.1,0.77-8.95,1.65c-1.41,0.45-2.8,1.01-3.92,1.79
		c-1.13,0.78-1.86,1.79-1.92,2.87c0.01,0.28,0.03,0.56,0.07,0.85l0.26,0.88c0.24,0.62,0.56,1.22,0.93,1.8
		c0.76,1.16,1.72,2.23,2.8,3.21c2.15,1.98,4.65,3.66,7.23,5.26c2.59,1.61,5.27,3.07,7.99,4.82c0.68,0.45,1.37,0.91,2.07,1.48
		c0.35,0.28,0.71,0.6,1.07,0.97c0.36,0.39,0.76,0.84,1.11,1.62c0.17,0.39,0.31,0.91,0.31,1.51c-0.01,0.61-0.21,1.29-0.5,1.74
		c-0.61,0.95-1.25,1.31-1.77,1.63c-1.05,0.57-1.92,0.81-2.77,1.05c-6.58,1.58-13.07,1.41-19.4,0.92
		c-6.32-0.52-12.53-1.55-18.49-2.17c-1.48-0.14-2.94-0.26-4.33-0.29c-1.37-0.03-2.71,0.05-3.64,0.27c-0.4,0.12-0.78,0.26-0.76,0.32
		c-0.07,0.02,0.07,0.01,0.08-0.05c0.1-0.04,0.13-0.16,0.18-0.18c0.01-0.1,0.06,0.01,0.03,0.01l0.03,0.71c-0.04,0.48,0.14,1,0.19,1.5
		c0.49,2.04,1.74,4.2,3.41,6.19c0.83,1,1.78,1.95,2.76,2.87c0.5,0.45,1.01,0.89,1.53,1.32l0.79,0.61c0.25,0.18,0.56,0.41,0.71,0.51
		l-5.86,8.14c-0.48-0.37-0.77-0.62-1.12-0.92l-0.99-0.88c-0.64-0.6-1.26-1.21-1.87-1.84c-1.2-1.27-2.35-2.6-3.4-4.06
		c-1.04-1.47-2.03-3-2.82-4.74c-0.83-1.69-1.42-3.62-1.77-5.62c-0.1-1.04-0.27-2.05-0.18-3.12c0.03-0.54,0.04-1.02,0.1-1.61
		c0.15-0.75,0.23-1.43,0.61-2.23c0.29-0.77,0.8-1.5,1.35-2.17c0.58-0.58,1.25-1.18,1.88-1.5c1.33-0.79,2.48-1.03,3.58-1.25
		c2.12-0.32,3.9-0.23,5.61-0.09c1.7,0.16,3.31,0.4,4.9,0.66c6.29,1.1,12.22,2.5,18.2,3.4c2.98,0.45,5.95,0.78,8.9,0.9
		c1.47,0.05,2.94,0.06,4.39-0.02c1.44-0.07,2.94-0.21,4.26-0.46c0.65-0.12,1.28-0.3,1.61-0.44c0.16-0.05,0.18-0.14-0.01,0.15
		c-0.07,0.13-0.18,0.42-0.16,0.64c0,0.1,0.01,0.2,0.03,0.27c0,0.04,0.04,0.12,0.04,0.12c0.05,0.06-0.03-0.11-0.19-0.29
		c-0.16-0.19-0.36-0.41-0.6-0.63c-0.47-0.44-1.04-0.89-1.62-1.33c-2.37-1.76-5.02-3.46-7.57-5.29c-2.56-1.84-5.1-3.79-7.35-6.17
		c-1.12-1.19-2.17-2.5-3.01-3.99c-0.42-0.75-0.78-1.54-1.03-2.39c-0.12-0.44-0.2-0.91-0.29-1.37c-0.03-0.48-0.04-0.98,0-1.47
		c0.17-0.98,0.53-1.98,1.16-2.7c0.28-0.4,0.63-0.71,0.98-1c0.34-0.32,0.72-0.52,1.08-0.77c1.49-0.89,3.06-1.37,4.61-1.76
		c3.12-0.74,6.25-0.97,9.37-1.09C292.5,648.32,298.7,648.72,304.85,649.45z"
                />
              </g>
              <polyline
                class="st1"
                points="246.36,445.2 262.45,559.34 348.23,559.34 363.64,445.2 "
              />
              <g>
                <g class="st12">
                  <polyline
                    points="247.43,455.6 245.16,439.94 364.59,439.94 362.88,455.6 		"
                  />
                </g>
                <g>
                  <polyline
                    class="st0"
                    points="247.43,452.77 245.16,437.11 364.59,437.11 362.88,452.77 		"
                  />
                </g>
              </g>
              <g>
                <g class="st12">
                  <path
                    d="M318.62,311.15h-21.98c-3.83,0-6.93-3.1-6.93-6.93v-21.98c0-3.83,3.1-6.93,6.93-6.93h21.98c3.83,0,6.93,3.1,6.93,6.93
			v21.98C325.55,308.04,322.44,311.15,318.62,311.15z"
                  />
                  <path
                    class="st14"
                    d="M318.62,311.15h-21.98c-3.83,0-6.93-3.1-6.93-6.93v-21.98c0-3.83,3.1-6.93,6.93-6.93h21.98
			c3.83,0,6.93,3.1,6.93,6.93v21.98C325.55,308.04,322.44,311.15,318.62,311.15z"
                  />
                </g>
                <g>
                  <path
                    class="st15"
                    d="M315.78,308.31h-21.98c-3.83,0-6.93-3.1-6.93-6.93v-21.98c0-3.83,3.1-6.93,6.93-6.93h21.98
			c3.83,0,6.93,3.1,6.93,6.93v21.98C322.71,305.21,319.61,308.31,315.78,308.31z"
                  />
                  <path
                    class="st2"
                    d="M315.78,308.31h-21.98c-3.83,0-6.93-3.1-6.93-6.93v-21.98c0-3.83,3.1-6.93,6.93-6.93h21.98
			c3.83,0,6.93,3.1,6.93,6.93v21.98C322.71,305.21,319.61,308.31,315.78,308.31z"
                  />
                </g>
              </g>
              <g>
                <g class="st12">
                  <path
                    d="M314.76,349.12H300.5c-2.48,0-4.5-2.01-4.5-4.5v-14.26c0-2.48,2.01-4.5,4.5-4.5h14.26c2.48,0,4.5,2.01,4.5,4.5v14.26
			C319.26,347.11,317.24,349.12,314.76,349.12z"
                  />
                  <path
                    class="st14"
                    d="M314.76,349.12H300.5c-2.48,0-4.5-2.01-4.5-4.5v-14.26c0-2.48,2.01-4.5,4.5-4.5h14.26
			c2.48,0,4.5,2.01,4.5,4.5v14.26C319.26,347.11,317.24,349.12,314.76,349.12z"
                  />
                </g>
                <g>
                  <path
                    class="st15"
                    d="M311.93,346.29h-14.26c-2.48,0-4.5-2.01-4.5-4.5v-14.26c0-2.48,2.01-4.5,4.5-4.5h14.26
			c2.48,0,4.5,2.01,4.5,4.5v14.26C316.42,344.27,314.41,346.29,311.93,346.29z"
                  />
                  <path
                    class="st2"
                    d="M311.93,346.29h-14.26c-2.48,0-4.5-2.01-4.5-4.5v-14.26c0-2.48,2.01-4.5,4.5-4.5h14.26
			c2.48,0,4.5,2.01,4.5,4.5v14.26C316.42,344.27,314.41,346.29,311.93,346.29z"
                  />
                </g>
              </g>
              <path
                class="st12"
                d="M304.94,203.48v355.86c0,0,14.62,0,43.29,0c0,0,4-39.34,12.54-92.15c12.23-75.62,12.51-193.42-39.55-263.71"
              />
            </svg>
          </div>
        </b-col>
      </b-row>

      <b-row class="my-5">
        <b-col cols="12" md="6" order="1" order-md="0 " data-aos="fade-left">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <svg
              width="463"
              height="336"
              viewBox="0 0 463 336"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_301_148)">
                <path d="M446.3 15H377.81V83.49H446.3V15Z" fill="#FFD400" />
                <path d="M446.3 121.2H377.81V189.69H446.3V121.2Z" fill="#58595B" />
                <path
                  d="M358 15H289.51V83.49H358V15Z"
                  stroke="#FFD400"
                  stroke-width="4"
                  stroke-miterlimit="10"
                />
                <path
                  d="M358 121.2H289.51V189.69H358V121.2Z"
                  stroke="#58595B"
                  stroke-width="4"
                  stroke-miterlimit="10"
                />
                <path
                  d="M114.43 232.18C100.54 217.15 92.05 197.06 92.05 174.98C92.05 128.43 129.79 90.7 176.33 90.7C222.87 90.7 260.61 128.44 260.61 174.98C260.61 179.89 260.19 184.71 259.38 189.39"
                  fill="url(#paint0_linear_301_148)"
                />
                <path d="M214.61 143.85H213.56V170.45H214.61V143.85Z" fill="#58595B" />
                <path
                  d="M212.83 148.83C206.26 144.65 199.68 153.01 193.11 148.83V143.85C199.68 148.03 206.26 139.67 212.83 143.85V148.83V148.83Z"
                  fill="#58595B"
                />
                <path
                  d="M212.83 157.16C206.26 152.98 199.68 161.34 193.11 157.16V152.18C199.68 156.36 206.26 148 212.83 152.18V157.16V157.16Z"
                  fill="#58595B"
                />
                <path
                  d="M258.97 189.91C277.96 203.08 306.24 221.64 320.74 226.89C340.07 233.89 352.07 243.55 354.4 244.22C354.4 244.22 316.41 243.89 270.09 257.55C228.14 269.92 195.11 274.21 157.45 274.21C119.79 274.21 78.13 274.21 47.48 294.87C47.48 294.87 62.81 268.54 133.96 267.71C187.94 267.08 193.01 261.14 217.77 255.55C236.73 251.27 303.08 232.89 308.42 234.89C309.21 235.19 309.71 234.05 308.96 233.67C304.54 231.45 296.05 227.79 279.76 221.9C244.77 209.24 229.11 193.24 229.11 196.57C229.11 199.9 240.11 225.23 240.11 225.23C240.11 225.23 224.11 198.57 221.11 198.24C218.11 197.91 210.11 239.23 210.11 239.23C210.11 239.23 216.11 198.57 211.44 197.57C206.77 196.57 176.78 242.89 175.12 242.89C173.45 242.89 195.45 202.9 194.45 201.9C193.45 200.9 163.46 227.56 151.79 232.73C140.13 237.9 130.46 251.23 124.13 256.23C124.13 256.23 136.46 239.9 132.8 239.9C129.13 239.9 90.48 269.56 88.48 269.73C86.48 269.9 108.47 248.24 105.81 248.24C103.14 248.24 59.16 267.57 47.82 271.23C36.49 274.9 0 288.56 0 288.56C0 288.56 71.56 252.98 86.89 243.32C102.22 233.66 93.22 236.32 110.88 232.99C112.07 232.77 113.26 232.5 114.45 232.2"
                  fill="#58595B"
                />
                <path
                  d="M114.43 232.17C130.93 227.99 147.29 216.81 154.44 211.22C162.11 205.22 201.26 167.23 206.26 167.56C211.26 167.89 208.59 171.56 217.59 170.56C226.59 169.56 230.25 169.23 243.92 179.22C247.34 181.72 252.64 185.51 258.97 189.9"
                  fill="#58595B"
                />
                <path
                  d="M159.12 284.54C232.99 287.56 277.41 271.71 306.9 258.21C336.39 244.71 367.22 254.71 367.22 254.71L426.05 290.2C426.05 290.2 362.39 270.7 318.9 283.67C302.91 288.44 263.59 306.2 218.1 302.7C172.61 299.2 136.44 293.7 96.4499 298.7C56.4599 303.7 24.6499 316.2 24.6499 316.2C24.6499 316.2 74.5499 281.08 159.12 284.54Z"
                  fill="#58595B"
                />
                <path
                  d="M170.41 196.95C170.41 196.95 187.76 193.78 189.12 199.87C191.51 210.59 204.95 181.97 209.23 179.08C213.51 176.19 213.12 193.6 224.33 186.71C236.97 178.94 266.53 195.1 266.53 195.1C266.53 195.1 246.81 178.44 231.81 171.77C226.59 169.45 222.21 169.6 219.06 170.35C219.06 170.35 210.89 171.53 209.4 168.19C209.4 168.19 206.42 166.74 204.25 168.09C204.25 168.09 201.7 169.7 197.69 172.83C191.16 177.92 180.84 186.82 170.41 196.95Z"
                  fill="url(#paint1_linear_301_148)"
                />
                <path
                  d="M79.89 224.89L79.55 225.14L76.53 227.37V231.73H74.73C74.73 231.73 74.64 227.45 72.63 229.88C70.61 232.32 73.88 233.24 73.88 233.24C73.88 233.24 74.01 235.51 73.88 235.93C73.76 236.35 70.36 238.57 70.23 238.57C70.1 238.57 67 232.23 64.52 228.96C62.04 225.68 63.73 224.05 63.73 224.05C63.73 224.05 68.14 221.11 73.05 218.34C77.97 215.57 78.89 218.47 80.19 220.73C81.48 223 79.89 224.89 79.89 224.89Z"
                  fill="#594A42"
                />
                <path
                  d="M105.14 230.62C105.14 230.62 104.25 229.97 101.16 230.35L101.26 230.02C101.26 230.02 99.6601 228.13 99.5901 227.63C99.5301 227.12 100.72 227.63 100.72 227.63V226.97L100.54 227C100.54 227 100.53 226.39 100.47 225.59C100.45 225.16 100.41 224.68 100.34 224.2C100.26 223.63 100.16 223.06 100.01 222.61C99.9601 222.48 99.9201 222.35 99.8701 222.24C99.8701 222.24 100.93 220.57 105.33 220.95C105.33 220.95 105.7 221.68 106.07 222.7C106.24 223.19 106.41 223.74 106.54 224.31C106.67 224.84 106.76 225.39 106.77 225.9C106.9 227.34 105.14 230.62 105.14 230.62Z"
                  fill="#FAB38F"
                />
                <path
                  d="M119.56 224.7C118.62 224.26 106.77 225.9 106.77 225.9C106.71 223.7 105.33 220.95 105.33 220.95L117.35 217.2C120.76 220.73 119.56 224.7 119.56 224.7Z"
                  fill="#58595B"
                />
                <path
                  d="M84.9299 228.24L81.4999 229.14C81.4599 228.9 81.4099 228.66 81.3499 228.43L84.5099 227.44C84.5399 227.52 84.5799 227.6 84.6399 227.69C84.7499 227.88 84.8499 228.06 84.9299 228.24Z"
                  fill="#58595B"
                />
                <path
                  d="M85.33 229.68L81.56 230.35C81.57 230.02 81.56 229.71 81.53 229.41L81.55 229.58L85.15 228.82C85.18 228.92 85.21 229.01 85.23 229.09C85.24 229.13 85.24 229.16 85.25 229.2C85.26 229.24 85.27 229.28 85.28 229.32C85.28 229.36 85.29 229.41 85.3 229.44C85.33 229.59 85.33 229.68 85.33 229.68Z"
                  fill="#58595B"
                />
                <path
                  d="M91.1799 225.48C91.1699 225.45 91.1499 225.41 91.1299 225.38L100.01 222.61C100.16 223.06 100.26 223.63 100.34 224.2L91.2799 226.58C91.3899 226.28 91.3699 225.91 91.1799 225.48Z"
                  fill="#58595B"
                />
                <path
                  d="M100.54 227L91.1799 228.65C91.1799 228.65 91.1799 228.65 91.1599 228.66L87.0799 229.38H87.0699C86.8999 229.29 86.7299 229.17 86.6099 229.01C86.4699 228.83 86.3899 228.69 86.3499 228.57L100.47 225.59C100.53 226.39 100.54 227 100.54 227Z"
                  fill="#58595B"
                />
                <path
                  d="M91.8199 340.86V341.68H80.8899L78.0299 303.65L74.7099 339.66L78.1999 341.01V341.68H68.8499C71.2499 315.73 70.9899 278.54 70.9899 278.54C70.9899 278.54 77.0899 281.27 87.7299 278.54L86.1599 338.65L91.8199 340.86Z"
                  fill="#58595B"
                />
                <path d="M87.76 278.55H87.75" stroke="black" stroke-miterlimit="10" />
                <path
                  d="M81.08 232.13L80.69 232.66C80.69 232.66 81.48 236.35 79.97 239.38H75.77C75.77 239.38 75.64 242.08 76.44 243.43C76.53 243.58 76.62 243.71 76.73 243.83L70.23 244.2V238.57C70.36 238.57 73.76 236.35 73.88 235.93C74 235.51 73.88 233.24 73.88 233.24C73.88 233.24 70.61 232.32 72.62 229.88C74.63 227.45 74.72 231.73 74.72 231.73H76.53V227.37L79.55 225.14C79.55 225.14 80.84 226.41 81.35 228.43C81.41 228.66 81.46 228.9 81.5 229.14L81.51 229.27L81.53 229.41C81.56 229.71 81.57 230.02 81.56 230.35C81.56 230.47 81.56 230.6 81.55 230.7L81.4 230.35C81.4 230.35 81.44 233.2 81.55 230.71L81.96 231.73L81.08 232.13Z"
                  fill="#FAB38F"
                />
                <path
                  d="M100.67 251.75C98.6499 253.69 88.3899 254.23 88.3899 254.23L87.7499 278.54C77.1099 281.27 71.0099 278.54 71.0099 278.54C70.0599 260.15 66.9999 260.11 65.0199 252.76C63.0499 245.42 70.2299 244.2 70.2299 244.2L76.7299 243.82L83.0799 244.32L88.8099 244.78L96.1699 245.37L101.15 230.34C104.24 229.96 105.13 230.61 105.13 230.61C105.58 236.16 102.69 249.83 100.67 251.75Z"
                  fill="#FFD400"
                />
                <path
                  d="M86.35 228.57C86.39 228.69 86.47 228.83 86.61 229.01C86.73 229.17 86.9 229.29 87.07 229.38H87.08C88.4 229.99 90.91 228.78 91.16 228.66C91.18 228.65 91.18 228.65 91.18 228.65C91.14 230.45 89.8 233.41 89.8 233.41C89.8 233.41 88.96 232.57 86.01 233.2C86.01 233.2 85.06 230.21 85.33 229.68C85.33 229.68 85.33 229.59 85.3 229.44C85.29 229.4 85.29 229.36 85.28 229.32C85.27 229.28 85.26 229.24 85.25 229.2C85.25 229.17 85.24 229.13 85.23 229.09C85.21 229.01 85.18 228.92 85.15 228.82C85.14 228.8 85.14 228.78 85.13 228.76C85.1 228.66 85.06 228.55 85.01 228.43C84.99 228.37 84.96 228.3 84.93 228.24C84.85 228.06 84.75 227.88 84.64 227.69C84.58 227.6 84.54 227.52 84.51 227.44L86.18 226.92C86.53 226.81 90.01 223.18 91.13 225.38C91.15 225.41 91.17 225.45 91.18 225.48C91.37 225.91 91.39 226.28 91.28 226.58C90.79 227.95 87.82 228.12 87.82 228.12C87.82 228.12 86.05 227.72 86.35 228.57Z"
                  fill="#FAB38F"
                />
                <path
                  d="M84.51 227.44C84.24 226.66 85.31 226.56 85.33 226.6C85.34 226.65 85.81 227.03 86.18 226.92L84.51 227.44Z"
                  fill="#FAB38F"
                />
                <path
                  d="M88.8198 244.79L83.0898 244.33C83.0898 244.33 83.2498 241.89 86.0198 233.2C88.9698 232.56 89.7998 233.41 89.7998 233.41C90.4298 236.18 88.8198 244.79 88.8198 244.79Z"
                  fill="#FFD400"
                />
                <path
                  d="M100.47 225.59L86.3499 228.57C86.0499 227.72 87.8199 228.12 87.8199 228.12C87.8199 228.12 90.7899 227.95 91.2799 226.58L100.34 224.2C100.41 224.68 100.45 225.16 100.47 225.59Z"
                  fill="#FFD400"
                />
                <path
                  d="M85.15 228.82L81.55 229.58L81.53 229.41L81.51 229.27L81.5 229.14L84.93 228.24C84.96 228.3 84.99 228.37 85.01 228.43C85.06 228.54 85.1 228.66 85.13 228.76C85.14 228.78 85.14 228.8 85.15 228.82Z"
                  fill="#FFD400"
                />
                <path
                  d="M106.07 222.7L118.82 219.2C118.82 219.2 119.37 219.9 119.66 221.67L106.54 224.31C106.54 224.31 106.48 223.73 106.07 222.7Z"
                  fill="#FFD400"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_301_148"
                  x1="176.337"
                  y1="232.176"
                  x2="176.337"
                  y2="90.694"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="#FFD400" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_301_148"
                  x1="218.473"
                  y1="202.258"
                  x2="218.473"
                  y2="167.517"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#58595B" />
                  <stop offset="1" stop-color="#FFD400" />
                </linearGradient>
                <clipPath id="clip0_301_148">
                  <rect width="463" height="336" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </b-col>

        <b-col cols="12" md="6" data-aos="fade-right">
          <div class="h-100 d-flex flex-column align-items-center justify-content-center">
            <h2 class="text-center">رؤيتنا</h2>
            <p class="text-center">
              كتبنا ومنتجاتنا الورقية والإلكترونية منتشرة عالميا كمواد ووسائل تعليم فعالة
              وأدوات بحث علمي موثوق
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row class="my-5">
        <b-col cols="12" md="6" data-aos="fade-left">
          <div class="d-flex flex-column justify-content-center h-100">
            <h2 class="my-2" data-aos="zoom-in">قيمنا</h2>
            <ul style="list-style: none" class="m-0 p-0">
              <li
                class="align-items-center d-flex"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <img src="/images/pen.svg" class="ml-4" alt="" />

                <p>الأمانة العلمية</p>
              </li>
              <li
                class="align-items-center d-flex"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <img src="/images/pen.svg" class="ml-4" alt="" />

                <p>المشاركة والعمل المؤسساتي</p>
              </li>
              <li
                class="align-items-center d-flex"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <img src="/images/pen.svg" class="ml-4" alt="" />

                <p>الإبداع والأصالة</p>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" md="6" data-aos="fade-right">
          <svg
            class="w-100"
            viewBox="0 0 842 581"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M419.015 215.503C415.864 192.87 413.504 195.934 406.413 187.677C400.582 180.796 392.711 174.677 390.191 168.108C389.721 166.576 389.401 165.053 389.401 163.521C389.561 155.879 397.272 135.383 389.091 126.52C387.991 125.3 386.731 124.383 385.15 123.612H384.99C384.99 123.612 381.84 129.117 378.69 137.071C376.959 141.043 375.229 145.787 373.809 150.53C370.028 164.751 366.568 160.778 378.22 181.567C389.871 202.513 402.473 219.642 402.783 229.275C403.113 238.898 422.165 238.293 419.015 215.503Z"
              fill="#F6C798"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M388.281 177.429C388.281 177.429 389.061 173.457 390.161 168.108C389.691 166.576 389.381 165.053 389.381 163.521C389.541 155.879 397.192 135.392 389.071 126.52C387.661 133.245 386.73 154.034 386.41 159.392C386.25 165.043 388.281 177.429 388.281 177.429Z"
              fill="#F2B679"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M385.1 123.602H384.94C384.94 123.602 381.83 129.117 378.719 137.081C382.46 135.861 388.681 132.494 385.1 123.602Z"
              fill="#F2F2F3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M144.429 169.845L700.782 138.818V145.504L148.8 179.586L144.429 169.845Z"
              fill="#58595B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M476.752 145.611C475.172 141.483 472.342 139.189 468.401 138.115C464.621 136.895 462.1 133.987 458.79 131.84C458.47 131.683 458.32 131.537 458 131.381C456.42 130.463 454.849 129.702 452.799 129.39C448.389 128.629 446.178 126.794 443.188 125.105C441.298 123.885 438.937 122.655 435.307 121.738C434.517 121.582 433.727 121.279 432.947 121.279C425.226 119.903 423.495 123.114 412.624 119.142C411.674 118.839 410.734 118.527 409.944 118.224C394.822 113.783 389.311 123.124 389.621 131.996C390.091 141.326 387.261 151.116 386.47 171.778C385.68 192.43 394.502 203.45 397.812 213.083C401.282 222.872 401.282 245.672 401.282 245.672C461.78 246.131 461.78 246.131 461.78 246.131C461.78 236.185 462.57 217.67 467.291 210.633C472.172 203.596 474.542 188.146 475.322 181.411C476.272 174.53 475.952 174.677 477.532 163.355C478.642 155.401 478.322 149.74 476.752 145.611Z"
              fill="#F6C798"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M395.382 251.04L394.761 220.208L469.031 219.378C468.591 230.339 468.831 241.738 468.391 252.709L395.382 251.04Z"
              fill="#58595B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M388.381 230.495H475.992V504.227H386.69L388.381 230.495Z"
              fill="#FFD400"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M666.907 143.669C666.907 137.325 672.148 132.181 678.619 132.181C685.09 132.181 690.33 137.325 690.33 143.669C690.33 150.013 685.09 155.157 678.619 155.157C672.148 155.157 666.907 150.013 666.907 143.669Z"
              fill="#58595B"
            />
            <path
              d="M666.907 143.669C666.907 137.325 672.148 132.181 678.619 132.181C685.09 132.181 690.33 137.325 690.33 143.669C690.33 150.013 685.09 155.157 678.619 155.157C672.148 155.157 666.907 150.013 666.907 143.669Z"
              fill="#FFD400"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M777.822 339.096C760.369 339.096 760.369 339.096 760.369 339.096C581.306 339.096 581.306 339.096 581.306 339.096C578.716 339.096 576.785 340.989 576.785 343.517C576.785 346.045 578.726 347.938 581.306 347.938C760.369 347.938 760.369 347.938 760.369 347.938C777.822 347.938 777.822 347.938 777.822 347.938C780.192 347.938 782.342 346.045 782.342 343.517C782.352 340.999 780.192 339.096 777.822 339.096Z"
              fill="#58595B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M156.97 172.627C156.97 166.312 162.241 161.198 168.742 161.198C175.243 161.198 180.514 166.312 180.514 172.627C180.514 178.942 175.243 184.056 168.742 184.056C162.241 184.056 156.97 178.942 156.97 172.627Z"
              fill="#58595B"
            />
            <path
              d="M156.97 172.627C156.97 166.312 162.241 161.198 168.742 161.198C175.243 161.198 180.514 166.312 180.514 172.627C180.514 178.942 175.243 184.056 168.742 184.056C162.241 184.056 156.97 178.942 156.97 172.627Z"
              fill="#FFD400"
            />
            <path
              d="M169.382 178.776L88.2314 369.947"
              stroke="#FFD400"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M266.895 368.864C249.443 368.864 249.443 368.864 249.443 368.864C70.3792 368.864 70.3792 368.864 70.3792 368.864C67.7889 368.864 65.8586 370.738 65.8586 373.236C65.8586 375.735 67.7989 377.609 70.3792 377.609C249.443 377.609 249.443 377.609 249.443 377.609C266.895 377.609 266.895 377.609 266.895 377.609C269.265 377.609 271.415 375.735 271.415 373.236C271.415 370.738 269.265 368.864 266.895 368.864Z"
              fill="#58595B"
            />
            <path
              d="M225.452 279.489L107.077 279.747L107.112 295.305L225.488 295.047L225.452 279.489Z"
              fill="#58595B"
            />
            <path
              d="M207.062 284.618L173.327 284.691L173.339 290.078L207.074 290.006L207.062 284.618Z"
              fill="#FFD400"
            />
            <path
              d="M154.054 284.618L120.32 284.691L120.332 290.078L154.067 290.006L154.054 284.618Z"
              fill="#FFD400"
            />
            <path
              d="M217.51 297.098L107.106 297.334L107.143 314.101L217.548 313.865L217.51 297.098Z"
              fill="#58595B"
            />
            <path
              d="M212.032 315.896L107.148 316.131L107.181 329.893L212.064 329.658L212.032 315.896Z"
              fill="#58595B"
            />
            <path d="M220.269 349.471H107.414V368.62H220.269V349.471Z" fill="#58595B" />
            <path
              d="M130.118 349.447L130.095 368.596L139.906 368.607L139.93 349.458L130.118 349.447Z"
              fill="#FFD400"
            />
            <path
              d="M114.787 349.415L114.767 368.574L123.358 368.583L123.378 349.424L114.787 349.415Z"
              fill="#FFD400"
            />
            <path
              d="M181.023 349.488L181.001 368.647L196.954 368.664L196.975 349.505L181.023 349.488Z"
              fill="#FFD400"
            />
            <path
              d="M203.103 349.508L203.082 368.667L215.354 368.68L215.375 349.521L203.103 349.508Z"
              fill="#FFD400"
            />
            <path
              d="M162.311 356.654C155.08 356.644 149.109 361.837 148.059 368.61L176.533 368.639C175.503 361.866 169.542 356.664 162.311 356.654Z"
              fill="#FFD400"
            />
            <path
              d="M226.172 331.432L107.177 331.691L107.212 347.258L226.208 347L226.172 331.432Z"
              fill="#58595B"
            />
            <path d="M159.341 316.276H155.18V329.667H159.341V316.276Z" fill="#FFD400" />
            <path d="M179.353 316.276H175.193V329.667H179.353V316.276Z" fill="#FFD400" />
            <path d="M199.096 316.276H194.936V329.667H199.096V316.276Z" fill="#FFD400" />
            <path
              d="M115.955 309.542C118.296 308.517 119.706 306.204 120.976 304.037C121.516 303.11 122.056 302.183 122.596 301.256C123.066 303.149 123.536 305.033 124.006 306.926C124.086 307.258 124.186 307.619 124.456 307.853C124.676 308.049 124.986 308.117 125.287 308.156C128.267 308.576 131.417 306.575 132.217 303.735C132.928 303.891 133.388 304.525 133.848 305.072C135.638 307.219 138.258 308.605 140.989 309.337C143.719 310.079 146.589 310.225 149.42 310.225C157.441 310.245 165.472 309.22 173.213 307.19"
              stroke="#939598"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M111.245 326.29C113.315 326.593 115.425 326.661 117.515 326.495C118.236 326.437 118.986 326.349 119.616 326.007C120.496 325.529 121.056 324.602 121.206 323.626C121.356 322.66 121.126 321.664 120.696 320.776C120.586 320.551 120.416 320.298 120.166 320.298C119.976 320.298 119.816 320.444 119.686 320.581C119.086 321.205 118.476 321.83 117.875 322.455C116.925 321.381 117.465 319.429 118.846 318.97C119.116 320.327 119.386 321.684 119.656 323.04C119.786 323.694 119.956 324.407 120.506 324.787C120.836 325.021 121.266 325.1 121.676 325.08C122.736 325.041 123.716 324.455 124.426 323.684C125.136 322.913 125.626 321.976 126.107 321.049C126.137 322.757 126.167 324.475 126.207 326.183C126.387 325.783 126.567 325.373 126.747 324.973C134.528 325.09 142.319 324.982 150.1 324.651"
              stroke="#939598"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M140.509 341.487C144.119 341.76 147.749 341.819 151.37 341.653C151.64 341.643 151.94 341.614 152.1 341.399C152.23 341.233 152.22 340.999 152.2 340.784C152.1 339.73 151.89 338.686 151.56 337.671C151.65 338.471 151.74 339.291 151.51 340.062C151.28 340.833 150.66 341.545 149.84 341.633C149.05 340.608 148.66 339.291 148.77 338.012C150.34 338.061 151.64 339.193 153.13 339.691C154.01 339.984 154.97 340.052 155.881 339.886C156.291 339.808 156.771 339.584 156.761 339.183C156.751 338.939 156.561 338.744 156.371 338.578C155.34 337.651 154.18 336.86 152.94 336.216C153.81 335.826 154.73 335.543 155.671 335.387C155.53 337.856 155.511 340.325 155.591 342.795C157.831 341.985 160.191 341.497 162.581 341.35C163.021 341.887 162.311 342.765 161.621 342.648C160.931 342.531 160.511 341.77 160.551 341.077C160.591 340.394 160.961 339.769 161.331 339.183C162.111 340.462 162.451 341.985 162.291 343.468C164.412 343.078 166.432 342.18 168.112 340.872C168.052 340.267 167.992 339.662 167.942 339.057C166.732 340.12 167.282 342.443 168.852 342.882C168.552 343.068 168.192 343.136 167.842 343.087C167.612 342.902 167.702 342.512 167.942 342.346C168.182 342.18 168.502 342.16 168.792 342.15C170.913 342.102 173.043 342.053 175.163 342.014C174.773 341.243 173.873 340.735 172.993 340.784C181.544 340.755 190.105 340.755 198.656 340.784"
              stroke="#939598"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M168.022 178.776L249.183 369.947"
              stroke="#FFD400"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M733.686 269.438C738.526 287.875 741.247 298.045 741.847 299.928C731.585 304.75 717.404 311.23 699.281 319.361C679.039 328.506 664.837 334.957 656.686 338.705C642.994 300.27 632.843 271.438 626.242 252.211C643.934 242.158 656.486 235.277 663.887 231.578C670.978 234.37 681.399 238.703 695.151 244.569C709.953 250.923 720.094 255.217 725.595 257.442C729.265 258.916 730.965 259.541 730.685 259.326C731.015 259.629 732.026 262.996 733.686 269.438Z"
              fill="#FFD400"
            />
            <path
              d="M664.327 329.872C672.348 326.32 683.539 321.274 697.921 314.725C713.123 307.844 724.215 302.856 731.195 299.753C734.316 298.376 735.916 297.557 735.986 297.273C735.916 297.547 735.476 296.161 734.646 293.096C734.086 291.017 733.146 287.367 731.846 282.155C730.745 277.802 729.825 274.23 729.095 271.468C727.595 265.865 726.795 262.996 726.695 262.85C726.875 263.025 724.655 262.205 720.034 260.39C714.343 258.126 705.162 254.319 692.51 248.961C679.139 243.31 669.527 239.308 663.657 236.976C660.156 238.859 654.935 241.719 647.995 245.564C640.994 249.429 635.763 252.318 632.302 254.212C635.053 262.742 638.963 274.103 644.034 288.265C650.205 305.462 654.225 316.813 656.096 322.308C658.326 328.779 659.486 332.029 659.576 332.068C659.496 332.019 661.076 331.287 664.327 329.872Z"
              fill="#5C5B60"
            />
            <path
              d="M678.719 248.912C676.298 250.835 672.948 253.343 668.647 256.437C663.797 259.961 660.456 262.43 658.636 263.826L639.333 255.803C639.394 255.412 640.094 254.837 641.434 254.056C653.875 247.106 660.956 243.173 662.677 242.246L678.719 248.912Z"
              fill="#FFD400"
            />
            <path
              d="M656.356 267.808C656.456 272.522 656.606 279.901 656.806 289.934C657.006 299.616 657.176 307.004 657.316 312.089C650.875 295.253 644.814 278.095 639.123 260.644L656.356 267.808Z"
              fill="#FFD400"
            />
            <path
              d="M704.122 287.65C699.071 292.228 692.24 298.298 683.629 305.862C673.598 314.627 666.767 320.649 663.136 323.909C663.036 321.605 662.426 303.588 661.306 269.867L704.122 287.65Z"
              fill="#FFD400"
            />
            <path
              d="M664.257 266.158C673.158 259.385 679.939 254.446 684.599 251.352L701.631 258.428C702.652 263.572 703.702 271.761 704.802 283.004L664.257 266.158Z"
              fill="#FFD400"
            />
            <path
              d="M675.808 320.005C679.779 316.208 685.249 311.201 692.23 304.964C701.051 297.03 706.672 291.944 709.062 289.709L726.495 296.951C720.174 299.977 711.753 303.862 701.201 308.625C688.41 314.334 679.939 318.131 675.808 320.005Z"
              fill="#FFD400"
            />
            <path
              d="M723.344 267.447C723.614 267.661 724.265 269.857 725.285 274.025C728.135 285.327 729.685 291.788 729.915 293.428L710.873 285.522C710.193 282.624 709.563 278.583 708.973 273.42C708.332 267.408 707.802 263.308 707.392 261.132C708.112 261.532 710.733 262.469 715.243 263.933C719.484 265.338 722.184 266.51 723.344 267.447Z"
              fill="#FFD400"
            />
            <path
              d="M678.959 149.115L760.109 340.286"
              stroke="#FFD400"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M680.319 149.115L599.158 340.286"
              stroke="#FFD400"
              stroke-width="2"
              stroke-miterlimit="10"
            />
          </svg>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import aos from "aos";
export default {
  data() {
    return {
      scrolledTo: false,
    };
  },

  created() {
    aos.init();
    window.addEventListener("scroll", () => {
      this.scrolledTo = window.scrollY > 500;
    });
  },
};
</script>
<style lang="scss">
.animated {
  background-size: 100vw !important;
}
.svg-bg2 {
  background-image: url("/images/bg2.svg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 150vw;
  transition: 1s;
}
.about {
  p,
  li {
    color: #4d4d4d;
    font-size: 21px;
  }
  h2 {
    color: #ffd400;
    margin: 20px 0 !important;
  }
  span {
    font-size: 24px !important;
    margin: 0 5px !important;
  }
  ul {
    list-style: none;
    li {
      display: flex;
    }
  }

  .st0 {
    fill: #ffd400;
  }
  .st1 {
    fill: #58595b;
  }
  .st2 {
    fill: none;
    stroke: #ffd400;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #58595b;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: url(#SVGID_1_);
  }
  .st5 {
    fill: url(#SVGID_2_);
  }
  .st6 {
    fill: #e9694e;
  }
  .st7 {
    fill: url(#SVGID_3_);
  }
  .st8 {
    fill: url(#SVGID_4_);
  }
  .st9 {
    fill: #ffffff;
  }
  .st10 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
  }
  .st11 {
    fill: none;
    stroke: #fff200;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
  .st12 {
    opacity: 0.3;
  }
  .st13 {
    opacity: 0.1;
  }
  .st14 {
    fill: none;
    stroke: #000000;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #808285;
  }
  .st16 {
    fill: #fab38f;
  }
  .st17 {
    fill: #594a42;
  }
  .st18 {
    fill: none;
  }
  .st19 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #f6c798;
  }
  .st20 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #f2b679;
  }
  .st21 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #f2f2f3;
  }
  .st22 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #58595b;
  }
  .st23 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffd400;
  }
  .st24 {
    fill: none;
    stroke: #ffd400;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st25 {
    fill: none;
    stroke: #939598;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
  .st26 {
    fill: #5c5b60;
  }

  .vision {
    .st0 {
      fill: #ffd400;
    }
    .st1 {
      fill: #58595b;
    }
    .st2 {
      fill: none;
      stroke: #ffd400;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }
    .st3 {
      fill: none;
      stroke: #58595b;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }
    .st4 {
      fill: url(#SVGID_1_);
    }
    .st5 {
      fill: url(#SVGID_2_);
    }
    .st6 {
      fill: #e9694e;
    }
    .st7 {
      fill: url(#SVGID_3_);
    }
    .st8 {
      fill: url(#SVGID_4_);
    }
    .st9 {
      fill: #ffffff;
    }
    .st10 {
      fill: none;
      stroke: #000000;
      stroke-miterlimit: 10;
    }
    .st11 {
      fill: none;
      stroke: #fff200;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }
    .st12 {
      opacity: 0.3;
    }
    .st13 {
      opacity: 0.1;
    }
    .st14 {
      fill: none;
      stroke: #000000;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }
    .st15 {
      fill: #808285;
    }
    .st16 {
      fill: #fab38f;
    }
    .st17 {
      fill: #594a42;
    }
    .st18 {
      fill: none;
    }
    .st19 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #f6c798;
    }
    .st20 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #f2b679;
    }
    .st21 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #f2f2f3;
    }
    .st22 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #58595b;
    }
    .st23 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #ffd400;
    }
    .st24 {
      fill: none;
      stroke: #ffd400;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }
    .st25 {
      fill: none;
      stroke: #939598;
      stroke-width: 0.5;
      stroke-miterlimit: 10;
    }
    .st26 {
      fill: #5c5b60;
    }
  }
}
</style>
