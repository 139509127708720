<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12" lg="6">
          <b-row>
            <b-col cols="12" data-aos="fade-down">
              <div class="flex-col-start h-100">
                <h2 class="text-right text-gray align-items-flex">خدماتنا</h2>
                <p class="g2">
                  يعمل مركزنا على تقديم خدمات عديدة ومميزة تسعى لرفد العملية التعليمية
                  وتطويرها والنهوض بالمستوى الفكري والثقافي ليواكب تطورات العصر
                </p>
              </div>
            </b-col>
            <b-col cols="12" lg="6" data-aos="fade-up">
              <div class="flex-col-start h-100">
                <svg
                  class="m-auto"
                  id="Layer_1"
                  height="300px"
                  data-name="Layer  1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 461.4 366.4"
                >
                  <g id="Floor">
                    <ellipse
                      id="Floor-2"
                      cx="230.7"
                      cy="233.2"
                      rx="230.7"
                      ry="133.2"
                      style="fill: #f5f5f5"
                    />
                  </g>
                  <g id="Shadows">
                    <path
                      id="Shadow"
                      d="M230.1,339.3s10.1,1.1,17-1.2,57-25.4,68.5-33.8,7.6-13.9,7.6-13.9,24.8-6,39.2-12.3,45.9-24.8,61.4-39.7-19.7-36.6-25.6-33.2-89.9,70-89.9,70-49.9-17.9-59.3-22.6-38.4,1.5-38.4,1.5l-5.4,8.4L146,296.7l78.8,45.7Z"
                      transform="translate(0 0)"
                      style="fill: #e0e0e0"
                    />
                    <path
                      id="Shadow-2"
                      d="M13.6,219.4a.9.9,0,0,1,.6-1l113-65.3a2.6,2.6,0,0,1,2.7,0l53.7,31c.8.4.8,1.1,0,1.5L70.7,250.9a3.2,3.2,0,0,1-2.7,0l-53.8-31A.6.6,0,0,1,13.6,219.4Z"
                      transform="translate(0 0)"
                      style="fill: #e0e0e0"
                    />
                  </g>
                  <g id="Books">
                    <g id="Books-2">
                      <polygon
                        points="124.5 159.4 171.3 186.2 171.3 107 124.5 80.2 124.5 159.4"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M160.5,113.2,115.4,87.4c4-.9,7.1-2.6,9.1-5L170,108.3C163,112.6,160.5,113.2,160.5,113.2Z"
                        transform="translate(0 0)"
                        style="fill: #f5f5f5"
                      />
                      <path
                        d="M111.8,87.5l1.9-1.1,44.1,25.3a5.5,5.5,0,0,0,4.7,0l6.2-3.6c1.3-.7,1.3-1.9,0-2.7L124.5,80.1l1.7-.9,44.9,25.5c2.1,1.2,2.1,3.2,0,4.5l-6.8,3.9a8.7,8.7,0,0,1-7.7,0Z"
                        transform="translate(0 0)"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M156.6,113.1a8.7,8.7,0,0,0,7.7,0l6.8-3.9a2.7,2.7,0,0,0,1.5-2.3v79.3a2.6,2.6,0,0,1-1.5,2.2l-6.8,3.9a8.1,8.1,0,0,1-7.7,0l-44.8-25.5V87.5Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M160.5,193.3a6.4,6.4,0,0,0,3.8-1l6.8-3.9a2.6,2.6,0,0,0,1.5-2.2V106.9a2.7,2.7,0,0,1-1.5,2.3l-6.8,3.9a8.5,8.5,0,0,1-3.8.9Z"
                        transform="translate(0 0)"
                        style="fill: #3d3d3d"
                      />
                      <polygon
                        points="113.1 173.1 152.3 195.9 152.3 120.9 113.1 98.2 113.1 173.1"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M142.1,126.8,104.5,105a16.6,16.6,0,0,0,8.7-4.7l37.9,21.8C144.5,126.2,142.1,126.8,142.1,126.8Z"
                        transform="translate(0 0)"
                        style="fill: #f5f5f5"
                      />
                      <path
                        d="M101.1,105.1l1.8-1,36.7,21.3a4.9,4.9,0,0,0,4.4,0l5.9-3.4a1.2,1.2,0,0,0,.9-.9,1.3,1.3,0,0,0-.9-1.6L113.2,98.1l1.5-.9,37.4,21.6c2,1.2,2,3,0,4.2l-6.4,3.7a8.5,8.5,0,0,1-7.3,0Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M138.4,126.7a7.9,7.9,0,0,0,7.3,0l6.4-3.7a2.3,2.3,0,0,0,1.5-2.1v75a2.7,2.7,0,0,1-1.5,2.1l-6.4,3.6a7.9,7.9,0,0,1-7.3,0l-37.3-21.5v-75Z"
                        transform="translate(0 0)"
                        style="fill: #ffd504"
                      />
                      <path
                        d="M142.1,202.5a6.4,6.4,0,0,0,3.6-.9l6.4-3.6a2.7,2.7,0,0,0,1.5-2.1v-75a2.3,2.3,0,0,1-1.5,2.1l-6.4,3.7a6.4,6.4,0,0,1-3.6.9Z"
                        transform="translate(0 0)"
                        style="fill: #d3aa00"
                      />
                      <polygon
                        points="115.2 140.8 114 144.2 63.6 117.1 64.1 111.4 115.2 140.8"
                        style="fill: #3d3d3d"
                      />
                      <polygon
                        points="107 148 57.2 118.9 65.7 114 114.5 142.2 107 148"
                        style="fill: #f5f5f5"
                      />
                      <path
                        d="M103.5,148.2l2,1.2a2,2,0,0,0,1.5.4l16.3,63.9a2.4,2.4,0,0,1-1.4-.5L93.5,196.9a4.4,4.4,0,0,1-1.8-3.2L97.8,145Z"
                        transform="translate(0 0)"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M107,149.8l16.3,63.9a3.4,3.4,0,0,0,2.2-.5l8.8-7.5c.6-.3.6-1.5.3-2h0l-16.1-63.3c.2.5-.2.9-.6,1.5l-3.7,3.2-5.1,4.3A3.1,3.1,0,0,1,107,149.8Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M66.5,110l-2.4,1.4,50.2,29.2c.4.2.4.5,0,.7l-6.4,5.5a1.3,1.3,0,0,1-1.2,0l-6.1-3.5a4,4,0,0,0-3.2-.2c-.8.5-.6,1.3.4,1.9l7.7,4.4a3.8,3.8,0,0,0,3.6,0l8.8-7.5c.7-.9,1-1.5,0-2.1Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <polygon
                        points="54.3 199.9 100.1 226.5 100.1 138.9 54.3 112.3 54.3 199.9"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M88.1,145.8,44.2,120.3c4.5-1.1,7.8-2.9,10.1-5.6l44.3,25.6C91,145.1,88.1,145.8,88.1,145.8Z"
                        transform="translate(0 0)"
                        style="fill: #f5f5f5"
                      />
                      <path
                        d="M40.2,120.4l2.1-1.2,42.9,24.9a5.7,5.7,0,0,0,5.1,0l7-3.9c1.4-.9,1.4-2.2,0-3l-43-24.9,1.9-1.1,43.6,25.2c2.4,1.4,2.4,3.6,0,4.9l-7.4,4.3a9.1,9.1,0,0,1-8.5,0Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M83.9,145.6a9.1,9.1,0,0,0,8.5,0l7.4-4.3a2.8,2.8,0,0,0,1.8-2.4v87.6q0,1.4-1.8,2.4l-7.4,4.3a9.1,9.1,0,0,1-8.5,0L40.2,208V120.4Z"
                        transform="translate(0 0)"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M88.1,234.2a8,8,0,0,0,4.3-1l7.4-4.3a2.7,2.7,0,0,0,1.8-2.4V138.9a2.8,2.8,0,0,1-1.8,2.4l-7.4,4.3a8,8,0,0,1-4.3,1Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <polygon
                        points="35.6 210.7 81.4 237.3 81.4 149.7 35.6 123.1 35.6 210.7"
                        style="fill: #3d3d3d"
                      />
                      <path
                        d="M69.4,156.6,25.5,131.1a17.6,17.6,0,0,0,10.1-5.6l44.3,25.6C72.2,155.9,69.4,156.6,69.4,156.6Z"
                        transform="translate(0 0)"
                        style="fill: #ebebeb"
                      />
                      <path
                        d="M69.4,156.6,25.5,131.1a17.6,17.6,0,0,0,10.1-5.6l44.3,25.6C72.2,155.9,69.4,156.6,69.4,156.6Z"
                        transform="translate(0 0)"
                        style="fill: #f5f5f5"
                      />
                      <path
                        d="M21.5,131.2l2.1-1.2,42.9,24.9a5.7,5.7,0,0,0,5.1,0l6.9-3.9c1.5-.8,1.5-2.2,0-3L35.6,123.1l1.9-1.1,43.6,25.2c2.4,1.4,2.4,3.6,0,4.9l-7.4,4.3a9.3,9.3,0,0,1-8.6,0Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M65.1,156.4a9.3,9.3,0,0,0,8.6,0l7.4-4.3a2.7,2.7,0,0,0,1.8-2.4v87.6a2.8,2.8,0,0,1-1.8,2.4L73.7,244a9.3,9.3,0,0,1-8.6,0L21.5,218.8V131.2Z"
                        transform="translate(0 0)"
                        style="fill: #ffd504"
                      />
                      <path
                        d="M69.4,245.1a8.1,8.1,0,0,0,4.3-1.1l7.4-4.3a2.8,2.8,0,0,0,1.8-2.4V149.7q0,1.4-1.8,2.4l-7.4,4.3a8.1,8.1,0,0,1-4.3,1.1Z"
                        transform="translate(0 0)"
                        style="opacity: 0.15000000596046448; isolation: isolate"
                      />
                    </g>
                  </g>
                  <g id="Speech_Bubble" data-name="Speech Bubble">
                    <g id="Speach_Bubble" data-name="Speach Bubble">
                      <path
                        d="M241.4,85.7h0c.2,0,.3-.1.4-.3l5.1-8.8V69.8L235,62.9v6.9l3.9,11.1L234.1,90Z"
                        transform="translate(0 0)"
                        style="fill: #27debf"
                      />
                      <path
                        d="M241.4,85.7h0c.2,0,.3-.1.4-.3l5.1-8.8V69.8L235,62.9v6.9l3.9,11.1L234.1,90Z"
                        transform="translate(0 0)"
                        style="fill: #ffd504"
                      />
                      <path
                        d="M205.8,57.4a7.9,7.9,0,0,0,3.6,6.1l18.2,10.6L233,89.6l.3.4a.8.8,0,0,0,1.1-.3l5-8.8,14.3,8.3a8.1,8.1,0,0,0,7.1,0l2.9-1.7a7.8,7.8,0,0,0,3.5-6.2V32.6a7.8,7.8,0,0,0-3.5-6.2L219.4.8a8.1,8.1,0,0,0-7.1,0l-2.9,1.7a8,8,0,0,0-3.6,6.1Z"
                        transform="translate(0 0)"
                        style="fill: #ffd504"
                      />
                      <path
                        d="M267.2,32.6a8.8,8.8,0,0,0-1-3.5l-10,5.8a7.4,7.4,0,0,1,1,3.5V87.1c0,2-1.2,3-2.9,2.3a7.8,7.8,0,0,0,6.5-.2l2.9-1.7a7.8,7.8,0,0,0,3.5-6.2Z"
                        transform="translate(0 0)"
                        style="opacity: 0.20000000298023224; isolation: isolate"
                      />
                      <path
                        d="M209.4,2.5,212.3.8a8.1,8.1,0,0,1,7.1,0l44.3,25.6a10.6,10.6,0,0,1,2.5,2.7l-10,5.8a7.2,7.2,0,0,0-2.5-2.7L209.4,6.6c-1.7-1-3-.5-3.5,1A8,8,0,0,1,209.4,2.5Z"
                        transform="translate(0 0)"
                        style="fill: #fff; opacity: 0.5; isolation: isolate"
                      />
                      <path
                        d="M236.2,64.6l-4.7,2.7-5-8.4c-4.6-8.7-10.1-17-10-26.1,0-2.9.7-5.2,1.9-6.5h0c1.4-1.5,3.4-1.8,5.6-.5,3.9,2.2,7,10.5,7.5,16.5.5-5.4,3.7-10,7.5-7.7,2.2,1.3,4.2,3.9,5.6,7.1h0a22,22,0,0,1,1.9,8.7C246.4,59.6,240.9,61.4,236.2,64.6Z"
                        transform="translate(0 0)"
                        style="fill: #fafafa"
                      />
                    </g>
                  </g>
                  <g id="Character">
                    <g id="Character-2">
                      <path
                        d="M167.3,263.9l3.8,11.6L208.2,262l12.1-3.9A15.9,15.9,0,0,0,231,246.6l18.5-62.9c-19.5-5.7-30,5.1-33.8,20.6-4.4,18.4-7.5,35.8-7.5,35.8Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M233.9,239.8c-18.7,1.9-24.5-8.8-24.5-8.8s3-17.9,7.2-31.4,12.1-15.7,18.1-17.4,18.6,1.5,18.6,1.5Z"
                        transform="translate(0 0)"
                        style="fill: #ffd504"
                      />
                      <g id="Book">
                        <polygon
                          points="273.9 304.9 196 259.9 146.3 288.7 224.2 333.6 273.9 304.9"
                          style="fill: #27debf"
                        />
                        <polygon
                          points="273.9 304.9 196 259.9 146.3 288.7 224.2 333.6 273.9 304.9"
                          style="fill: #3d3d3d"
                        />
                        <path
                          d="M148.3,288.7v-6.2l31,17.8c3.8,2.2,6.9,7.1,6.9,8.8v1.4Z"
                          transform="translate(0 0)"
                          style="fill: #f5f5f5"
                        />
                        <path
                          d="M196.1,255h.1l30.9,17.8c3.8,2.2,6.9,7.1,6.9,8.9V283h0l-47.7,27.5v-1.4c0-1.7-3.1-6.6-6.9-8.8l-31-17.8Z"
                          transform="translate(0 0)"
                          style="fill: #fafafa"
                        />
                        <path
                          d="M196.1,255l31,17.8c3.8,2.2,6.9,7.1,6.9,8.9V283h0l-47.7,27.5v-1.4c0-1.7-3.1-6.6-6.9-8.8l-31-17.8Z"
                          transform="translate(0 0)"
                          style="fill: #fafafa"
                        />
                        <path
                          d="M188.3,306a7.3,7.3,0,0,1,4.8-.7l-3.9,2.2-2.9,1.7A3.8,3.8,0,0,1,188.3,306Z"
                          transform="translate(0 0)"
                          style="fill: #f0f0f0"
                        />
                        <path
                          d="M188.3,306a7.3,7.3,0,0,1,4.8-.7l-3.9,2.2-2.9,1.7A3.8,3.8,0,0,1,188.3,306Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="273.9 306.2 273.9 304.9 224.2 333.6 224.1 334.9 273.9 306.2"
                          style="fill: #27debf"
                        />
                        <polygon
                          points="273.9 306.2 273.9 304.9 224.2 333.6 224.1 334.9 273.9 306.2"
                          style="fill: #3d3d3d"
                        />
                        <polygon
                          points="224.2 333.6 146.3 288.7 146.2 290 224.1 334.9 224.2 333.6"
                          style="fill: #ffd504"
                        />
                        <path
                          d="M186.2,310.5l38,21.9V326l-31-17.7c-3.8-2.2-6.9-.8-6.9.9S186.2,310.5,186.2,310.5Z"
                          transform="translate(0 0)"
                          style="fill: #f5f5f5"
                        />
                        <polygon
                          points="271.8 305 271.8 298.6 224.2 326 224.2 332.4 271.8 305"
                          style="fill: #ebebeb"
                        />
                        <path
                          d="M187.1,307.8l48.3-27.9h0c1.1-.6,2.6-.7,5.4.9l31,17.7-5.6,3.2-42,24.3-29.6-16.9-1.4-.8C190.9,307,188.8,306.8,187.1,307.8Z"
                          transform="translate(0 0)"
                          style="fill: #fafafa"
                        />
                        <polygon
                          points="225.3 321.8 199 306.6 197.6 305.8 198.6 305.2 200 306 226.3 321.2 225.3 321.8"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="227.7 320.4 201.4 305.2 200 304.4 201 303.8 202.4 304.6 228.8 319.8 227.7 320.4"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="230.2 319 203.8 303.8 202.4 303 203.5 302.4 204.8 303.2 231.2 318.4 230.2 319"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="232.6 317.6 206.3 302.4 204.9 301.6 205.9 301 207.3 301.8 233.6 317 232.6 317.6"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="235 316.2 208.7 301 207.3 300.2 208.3 299.6 209.7 300.4 236.1 315.6 235 316.2"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="237.5 314.8 211.1 299.6 209.7 298.8 210.8 298.2 212.1 299 238.5 314.2 237.5 314.8"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="239.9 313.4 213.6 298.2 212.2 297.4 213.2 296.8 214.6 297.6 240.9 312.8 239.9 313.4"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="242.3 312 216 296.8 214.6 296 215.6 295.4 217 296.2 243.4 311.4 242.3 312"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="244.8 310.6 218.4 295.4 217 294.6 218.1 294 219.4 294.8 245.8 310 244.8 310.6"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="247.2 309.1 220.9 293.9 219.5 293.2 220.5 292.6 221.9 293.4 248.2 308.6 247.2 309.1"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="249.6 307.7 223.3 292.5 221.9 291.7 222.9 291.2 224.3 292 250.7 307.2 249.6 307.7"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="252.1 306.3 225.7 291.1 224.3 290.3 225.4 289.8 226.7 290.6 253.1 305.8 252.1 306.3"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="254.5 304.9 228.2 289.7 226.8 288.9 227.8 288.4 229.2 289.1 255.5 304.3 254.5 304.9"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="256.9 303.5 230.6 288.3 229.2 287.5 230.2 286.9 231.6 287.7 258 302.9 256.9 303.5"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="259.4 302.1 233 286.9 231.6 286.1 232.7 285.5 234 286.3 260.4 301.5 259.4 302.1"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="261.8 300.7 235.5 285.5 234.1 284.7 235.1 284.1 236.5 284.9 262.8 300.1 261.8 300.7"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="264.2 299.3 237.9 284.1 236.5 283.3 237.5 282.7 238.9 283.5 265.3 298.7 264.2 299.3"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M187.1,307.8l48.3-28h.3l.3.5,2-.7a13.9,13.9,0,0,1,2.8,1.2l23,13.2-43.6,25.1s-5.9-3.4-12.3-6.6a80.8,80.8,0,0,0-12.5-5.3l-2.5-.6A8.4,8.4,0,0,0,187.1,307.8Z"
                          transform="translate(0 0)"
                          style="opacity: 0.10000000149011612; isolation: isolate"
                        />
                        <polygon
                          points="228.9 325.2 224.2 327.9 219.5 325 224.2 327.4 228.9 325.2"
                          style="fill: #e6e6e6"
                        />
                        <polygon
                          points="233.9 325 224.2 330.3 215.5 325.6 224.2 330.6 233.9 325"
                          style="fill: #e6e6e6"
                        />
                        <polygon
                          points="271.8 300.6 264.5 304.4 271.8 300 271.8 300.6"
                          style="fill: #e6e6e6"
                        />
                        <polygon
                          points="271.8 303.1 261 309.1 271.8 302.5 271.8 303.1"
                          style="fill: #e6e6e6"
                        />
                        <path
                          d="M188.3,306l47.3-27.3h0c1.3-.7,3.3-1.2,6.6-.5,7.2,1.3,25.3,11.8,25.3,11.8l-47.7,27.4s-5.9-3.4-12.3-6.5c-4.7-2.4-9.7-4.7-12.5-5.3l-1.9-.3A7.3,7.3,0,0,0,188.3,306Z"
                          transform="translate(0 0)"
                          style="fill: #f5f5f5"
                        />
                        <path
                          d="M192.6,303.5a7.5,7.5,0,0,1,4.9-.7l1.8.3a52.4,52.4,0,0,1,7.8,3l4.7,2.3c3.9,1.9,7.5,3.9,9.9,5.2l1.1-.7-9.9-5.2-4.7-2.3a43,43,0,0,0-7.8-2.9l-1.9-.4a8.8,8.8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M195.1,302.1a7.3,7.3,0,0,1,4.8-.7,5.7,5.7,0,0,1,1.8.3,43.3,43.3,0,0,1,7.8,3l4.8,2.3,9.8,5.1,1.1-.6c-2.3-1.3-6-3.3-9.8-5.2l-4.8-2.3a43,43,0,0,0-7.8-2.9l-1.8-.4a8.8,8.8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M197.5,300.7a7.3,7.3,0,0,1,4.8-.7l1.9.3a43.3,43.3,0,0,1,7.8,3l4.7,2.3,9.9,5.1,1-.6c-2.3-1.3-6-3.3-9.8-5.2l-4.8-2.3a55.7,55.7,0,0,0-7.7-3l-1.9-.3a8.8,8.8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M200,299.2a8.8,8.8,0,0,1,4.8-.7l1.8.4a43.3,43.3,0,0,1,7.8,3l4.8,2.2,9.8,5.2,1.1-.6c-2.4-1.3-6-3.3-9.9-5.2l-4.7-2.3a52.4,52.4,0,0,0-7.8-3l-1.9-.3a8.8,8.8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M202.4,297.8a8.8,8.8,0,0,1,4.8-.7l1.9.4a50.8,50.8,0,0,1,7.7,2.9l4.8,2.3,9.8,5.2,1.1-.6c-2.3-1.3-6-3.3-9.8-5.2l-4.8-2.3a52.4,52.4,0,0,0-7.8-3l-1.8-.3a8.8,8.8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M204.8,296.4a8.8,8.8,0,0,1,4.8-.7l1.9.4a43,43,0,0,1,7.8,2.9l4.7,2.3,9.9,5.2,1-.6c-2.3-1.3-6-3.3-9.8-5.2l-4.7-2.3a52.4,52.4,0,0,0-7.8-3l-1.9-.3a8.8,8.8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M207.3,295a8.8,8.8,0,0,1,4.8-.7l1.8.3a52.4,52.4,0,0,1,7.8,3l4.8,2.3c3.8,1.9,7.5,3.9,9.8,5.2l1.1-.6c-2.3-1.3-6-3.3-9.9-5.2l-4.7-2.3a52.4,52.4,0,0,0-7.8-3l-1.8-.3a8,8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M209.7,293.6a8.8,8.8,0,0,1,4.8-.7l1.9.3a55.7,55.7,0,0,1,7.7,3l4.8,2.3c3.8,1.9,7.5,3.9,9.8,5.2l1.1-.6c-2.3-1.3-6-3.3-9.8-5.2l-4.8-2.3a51.2,51.2,0,0,0-7.7-3l-1.9-.3a8,8,0,0,0-4.8.7Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M212.1,292.2l1.1-.6a8,8,0,0,1,4.8-.7l1.9.3c2.8.6,7.8,2.9,12.5,5.3l9.9,5.2-1.1.6c-2.4-1.3-6-3.3-9.9-5.2a80.8,80.8,0,0,0-12.5-5.3l-1.8-.3A9.1,9.1,0,0,0,212.1,292.2Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M214.6,290.8l1.1-.6a8,8,0,0,1,4.8-.7l1.8.3c2.8.6,8.8,3.4,12.6,5.3s7.5,3.9,9.8,5.2l-1.1.6c-2.3-1.3-5-2.8-9.8-5.2s-9.7-4.6-12.6-5.3l-1.8-.3A8.8,8.8,0,0,0,214.6,290.8Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M217,289.4l1.1-.6a8,8,0,0,1,4.8-.7l1.9.3c2.8.6,8.7,3.4,12.5,5.3s7.5,3.9,9.8,5.2l-1,.6-9.9-5.2c-4.7-2.4-9.7-4.7-12.5-5.3l-1.9-.3A8,8,0,0,0,217,289.4Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M219.5,288l1-.6a7.3,7.3,0,0,1,4.8-.7l1.9.3c2.8.6,8.6,3.4,12.5,5.3s7.5,3.9,9.9,5.1l-1.1.7-9.9-5.2c-4.7-2.4-9.7-4.7-12.5-5.3l-1.8-.3A8,8,0,0,0,219.5,288Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M221.9,286.6l1.1-.6c1-.7,2.6-1,4.8-.7a5.7,5.7,0,0,1,1.8.3c2.9.6,7.8,2.9,12.6,5.3L252,296l-1.1.7c-2.3-1.3-5-2.8-9.8-5.2s-9.7-4.7-12.5-5.3l-1.9-.3A8,8,0,0,0,221.9,286.6Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M224.3,285.2l1.1-.7a8,8,0,0,1,4.8-.6l1.9.3c2.8.6,7.8,2.9,12.5,5.2l9.8,5.2-1,.7-9.9-5.2c-4.7-2.4-9.7-4.7-12.5-5.3l-1.9-.3A8,8,0,0,0,224.3,285.2Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M226.8,283.8l1-.7a9.1,9.1,0,0,1,4.9-.7l1.8.4c2.8.6,7.8,2.9,12.5,5.2l9.9,5.2-1.1.7c-2.3-1.3-5-2.8-9.8-5.2s-9.8-4.7-12.6-5.3a5.7,5.7,0,0,0-1.8-.3C229.4,282.8,227.8,283.1,226.8,283.8Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M229.2,282.4l1.1-.7a8.8,8.8,0,0,1,4.8-.7l1.9.4c2.8.6,8.7,3.3,12.5,5.2s7.5,3.9,9.8,5.2l-1.1.6-9.8-5.1c-4.8-2.4-9.7-4.7-12.5-5.3l-1.9-.3A7.3,7.3,0,0,0,229.2,282.4Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M231.6,280.9l1.1-.6a8.8,8.8,0,0,1,4.8-.7l1.9.3a80.8,80.8,0,0,1,12.5,5.3l9.9,5.2-1.1.6-9.9-5.1c-4.7-2.4-9.7-4.7-12.5-5.3l-1.9-.3A8,8,0,0,0,231.6,280.9Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M156.1,281.8l1-.6,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M158.5,280.4l1-.6,27.8,16a17,17,0,0,1,3.8,3.3l-1,.5a24.3,24.3,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M161,279l1-.6,27.7,16a17,17,0,0,1,3.8,3.3l-.9.5a30.9,30.9,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M163.4,277.6l1-.6,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.5a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M165.8,276.2l1-.6,27.8,16a17,17,0,0,1,3.8,3.3l-1,.5a24.3,24.3,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M168.3,274.7l1-.5,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.5a30.9,30.9,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M170.7,273.3l1-.5,27.7,16a17.6,17.6,0,0,1,3.9,3.2l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M173.1,271.9l1-.5,27.8,15.9a24.3,24.3,0,0,1,3.8,3.3l-1,.6a17,17,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M175.6,270.5l1-.5,27.7,15.9a30.9,30.9,0,0,1,3.9,3.3l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M178,269.1l1-.5,27.7,15.9a15.5,15.5,0,0,1,3.9,3.3l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M180.4,267.7l1-.6,27.8,16a24.3,24.3,0,0,1,3.8,3.3l-1,.6a17,17,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M182.9,266.3l1-.6,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M185.3,264.9l1-.6,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M187.7,263.5l1-.6,27.8,16a17,17,0,0,1,3.8,3.3l-1,.6a17,17,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M190.2,262.1l1-.6,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.6a15.5,15.5,0,0,0-3.9-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M192.6,260.7l1-.6,27.7,16a15.5,15.5,0,0,1,3.9,3.3l-1,.6a17,17,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <path
                          d="M195,259.3l1-.6,27.8,16a17,17,0,0,1,3.8,3.3l-1,.6a17,17,0,0,0-3.8-3.3Z"
                          transform="translate(0 0)"
                          style="fill: #e0e0e0"
                        />
                        <polygon
                          points="148.4 284 148.4 284.5 154.9 287.9 148.4 284"
                          style="fill: #e6e6e6"
                        />
                        <polygon
                          points="148.4 286 159.2 292.5 148.4 286.4 148.4 286"
                          style="fill: #e6e6e6"
                        />
                        <path
                          d="M231.7,286.1c-15.2,4.9-38.6,19.2-38.6,19.2a7.3,7.3,0,0,0-4.8.7l47.3-27.3h0c1.3-.7,2.4-1.3,6.6-.5s12,4.6,17.9,7.7A49.1,49.1,0,0,0,231.7,286.1Z"
                          transform="translate(0 0)"
                          style="opacity: 0.019999999552965164; isolation: isolate"
                        />
                      </g>
                      <path
                        d="M376.6,107.3a24.4,24.4,0,0,1,7.6-1c2.6.2,4.7.9,7.5,1.1s6.2,1.4,9,1.5a5.4,5.4,0,0,1,2.2.5,4.7,4.7,0,0,1,2.1,3.5,17.2,17.2,0,0,1-.5,6c-.2,1.5-.5,2.9-.7,4.3a6.5,6.5,0,0,1-.6,2.1c-1,1.8-3.8,3.1-5.5,3.9l-3.9,1.4-.3-1.2s-2-1.8-9.5,0-7.7,4.1-7.7,4.1l.5,1.7c-2.5.2-3.9-3.2-4.8-5.3a69.6,69.6,0,0,1-3.9-10.8C366.7,113.8,371.3,109,376.6,107.3Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M397.7,131.2l.3,1.3,3.9-1.2c1.8-.7,4.7-1.8,5.8-3.5a4.8,4.8,0,0,0,.7-2c.4-1.4.7-2.8,1.1-4.2a19.8,19.8,0,0,0,.9-6,4.9,4.9,0,0,0-1.8-3.7,8.6,8.6,0,0,0-2.3-.6c-2.7-.3-6.1-1.7-8.8-2.2h-.4s.2,7.7.6,12.9A56.5,56.5,0,0,1,397.7,131.2Z"
                        transform="translate(0 0)"
                        style="fill: #37474f"
                      />
                      <path
                        d="M413.3,137.3a3.3,3.3,0,0,1-1.5,1.1,12.2,12.2,0,0,1-14.7-3.3c-.9-1-1.8-2.1-2.8-3.1l4.2-11.6,19.5.5-3.4,12.6Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M396.1,104a54.6,54.6,0,0,0,.1,14c.2,1.5.4,4.3.4,4.3.9,1.7,3.6,4.1,9.6,5,8.5,1.3,11.5-2.3,11.5-2.3s5.5-8.5,10.4-11.3,16.6-9,21.6-13.7,5.6-12.8,5.1-16.6Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M412.8,91.3c6.9-2.7,14.5-13,28.4-13.8,18.6-1,15,9.7,8.2,14.9-5,3.9-17.9,9.7-27.5,15.3s-17.9,4.7-22.9,1C389.5,101.6,405.9,94,412.8,91.3Z"
                        transform="translate(0 0)"
                        style="fill: #37474f"
                      />
                      <path
                        d="M306.2,258l-21-21.2c6.5-11.3,13.8-24.8,43.9-31.5,6.1-1.3,24.9-4.1,37.4-6,3.7-18.9,29.3-72.2,29.3-72.2.8,3.9,16.5,7.7,19.4,4.3-6.1,30-11.6,69.1-16.4,77.3-2.8,4.7-5.6,6.6-16.6,11.7S326.9,246.3,306.2,258Z"
                        transform="translate(0 0)"
                        style="fill: #27debf"
                      />
                      <path
                        d="M306.2,258l-21-21.2c6.5-11.3,13.8-24.8,43.9-31.5,6.1-1.3,24.9-4.1,37.4-6,3.7-18.9,29.3-72.2,29.3-72.2.8,3.9,16.5,7.7,19.4,4.3-6.1,30-11.6,69.1-16.4,77.3-2.8,4.7-5.6,6.6-16.6,11.7S326.9,246.3,306.2,258Z"
                        transform="translate(0 0)"
                        style="fill: #232323"
                      />
                      <path
                        d="M384,129.4c7.5-1.9,9.5,0,9.5,0l.3,1.2,2,7.7L378.9,144c0-.4-1.1-4.9-2.1-8.8l-.5-1.7S376.5,131.3,384,129.4Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M314.9,222.7s15.7-9.4,34.1-3.5a262.9,262.9,0,0,1,36.2-7c-7.1-14-5.6-45.2-7.3-68.8-.3-4.7,16.5-8.2,18.8-6,0,0,28.8,84.2,21.3,94.3s-47,30.5-81.1,42.2Z"
                        transform="translate(0 0)"
                        style="fill: #282828"
                      />
                      <path
                        d="M381.7,200.8a48.7,48.7,0,0,0,3.5,11.4s5.8,3.2,16.3,2.3C401.5,214.5,384.8,210,381.7,200.8Z"
                        transform="translate(0 0)"
                        style="opacity: 0.05000000074505806; isolation: isolate"
                      />
                      <path
                        d="M225.2,217.3c3.9,18.8,9.3,31.2,24.9,46.6,7,6.8,23.8,17.4,35.2,18.2,24.9,1.9,39.7-3.3,52.1-7.2,5.4-9,6.6-26.3-1.2-41.5s-23.5-21.6-27.6-21.2c-2.4.5-6.5-1.3-10.6-5.6-4.3-6.2-6.7-11.1-12.4-13.9s-10.5-4.1-22.2-8.9C231.3,170.6,220.5,194.3,225.2,217.3Z"
                        transform="translate(0 0)"
                        style="fill: #3d3d3d"
                      />
                      <circle cx="224.6" cy="153.6" r="23.2" style="fill: #263238" />
                      <path
                        d="M236.3,193.9l5.7,8.3a10.7,10.7,0,0,0,10.9,1.3c9.7-3.7,10.8-11.3,9.3-15.3l-5.4-13Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M253.3,170.2l.7,8.7a9.4,9.4,0,0,0,6.9-5c1.3-2.8,2.5-9.1,2.5-9.1Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M256.7,166l6.7-1.2c3-9.4,4.1-18.9.9-21.7a8.6,8.6,0,0,0-6.9-1.9Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M267.5,156.9c-3-5.5-9.2-4.3-10.8-2.5s-2.1,3.6-3,6.3c0,0,4.5-17.1-8.7-24.3s-36.7-5.4-37.8,14.9c-1.1,18.6.4,26,3.6,35.3,2,5.7,5.7,9.7,11.1,10.2,3.3.3,9.9-1.1,9.9-1.1h0a59.7,59.7,0,0,0,9.3-3.1l4,1.3a41.3,41.3,0,0,0,7.7,1.9l2.7.3-1.6-18.8-.6-7.1a4.2,4.2,0,0,0,4.3,2.7,7.6,7.6,0,0,0,4.2-.9,3.1,3.1,0,0,0,1.3-.6C266.8,168.9,270.5,162.4,267.5,156.9Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M210,146.7c-3.4-1.4-6.9-2.9-9.6-5.4a6.1,6.1,0,0,1-2.1-3.3,4.8,4.8,0,0,1,2.3-5,9.9,9.9,0,0,1,5.6-1.1c-.7-2.8.6-5.5,3.6-7.2s5.8-1.4,8.7.5c-.7-2,.9-4.3,3-4.9s3.3-.3,6.3.6,6,1.9,9.1,2.7c5.4,1.3,11.1,1.9,16.1,4.4a9,9,0,0,1,3.2,2.3,8.1,8.1,0,0,1,1.8,5.1c0,2.3-.7,4.8-.4,6.6s.7,3.5,1,5.3.7,3.7,1.1,5.7c-2.2.5-4,1.8-6,7.7a26,26,0,0,0-.5,3.9,2.6,2.6,0,0,1-.9,2.1c-.7.5-2-.1-2.7-.6a9.5,9.5,0,0,1-2-1.5,24.3,24.3,0,0,1-8.3-14.5v-.5c-.1-.2-.5-2.1-.3-2.2A45,45,0,0,1,210,146.7Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M259,136.1h.2a4.5,4.5,0,0,1,6.1,1.8l-7.8,4.8A4.9,4.9,0,0,1,259,136.1Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M246.6,189.7a23.5,23.5,0,0,1-5.4,2.9l-4.7,1.7,2,2.9c6.5-2,10.2-5.4,11-6.5,2.3-3.4,3.4-9.8,3.4-9.8C252.3,182.6,249.1,187.7,246.6,189.7Z"
                        transform="translate(0 0)"
                        style="fill: #f28f8f"
                      />
                      <path
                        d="M226.9,169.6a2.4,2.4,0,0,1,2.3-2.5h0a2.5,2.5,0,0,1,2.5,2.3,2.4,2.4,0,0,1-2.3,2.6,2.4,2.4,0,0,1-2.5-2.3Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M228.8,160.7a3,3,0,0,1,3.5-2.2,3.3,3.3,0,0,1,2.3,3.7l-5.8-1.4Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M230.7,184.4a2.9,2.9,0,0,1-1.5,3.9h0a2.9,2.9,0,0,1-3.8-1.6l5.3-2.4Z"
                        transform="translate(0 0)"
                        style="fill: #f28f8f"
                      />
                      <path
                        d="M209.2,157.5h.1a2.9,2.9,0,0,1,3.8,1.4l-5.3,2.6A3.1,3.1,0,0,1,209.2,157.5Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <path
                        d="M210.2,169a2.5,2.5,0,0,1,2.3-2.7h0a2.5,2.5,0,0,1,2.5,2.3,2.4,2.4,0,1,1-4.8.4Z"
                        transform="translate(0 0)"
                        style="fill: #263238"
                      />
                      <polygon
                        points="214.1 181.2 222.9 181.5 220.6 167.1 214.1 181.2"
                        style="fill: #f28f8f"
                      />
                      <path
                        d="M164.1,278.6c1.4-2.6,1.8-5.3,2.8-6.8l2.7-1.5-2.3-6.4c-3.2,2.2-4.9,3-6.5,6.5s-1.2,7.5-2.3,10S161.7,282.8,164.1,278.6Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M280.5,233.6l11.2,48.5-35.6,29.4c-6.9-.9-13.5,2.4-18.9,1.9s-6.8,1.4-5.1,3,4,2.4,8.5,3.7,6,1.9,6,1.9l-18.1,10.4s12.1,2.2,22.1-1.2,53.5-27.7,62.3-33.6,8.8-6.7,7.9-15.5c-.7-6.5-7.8-39.9-12-54.7-6.4-23.1-9.6-30.2-25.4-34C270.9,200.2,280.5,233.6,280.5,233.6Z"
                        transform="translate(0 0)"
                        style="fill: #ffa8a7"
                      />
                      <path
                        d="M275.4,219.7c1.6,7.3,10.3,39,10.3,39s6.5,2.6,16.2,0,14.7-7.8,14.7-7.8-6.3-30.7-9.8-40.8-10.4-15.5-26.5-19.8C280.3,190.3,270.8,199.9,275.4,219.7Z"
                        transform="translate(0 0)"
                        style="fill: #ffd504"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row cols="1" cols-md="2">
            <b-col class="my-3" data-aos-duration="1000" data-aos="fade-down-left">
              <div
                class="shadow px-3 py-3 rounded d-flex flex-column justify-conent-center align-items-center serv-card"
              >
                <span class="mdi mdi-book text-yello fs-35"></span>
                <h4 class="text-gray fs-20">نشر الكتب</h4>
                <p class="g2 text-center fs-1">
                  تتنوع منشورات العلم النور من مناهج وألعاب وقصص تعليمية وغيرها إلى جانب
                  كونها متعددة اللغات وتعنى بالنشر الإلكتروني
                </p>
              </div>
            </b-col>
            <b-col class="my-3" data-aos-duration="1000" data-aos="fade-down-right">
              <div
                class="shadow px-3 py-3 rounded d-flex flex-column justify-conent-center align-items-center serv-card"
              >
                <span class="mdi mdi-printer text-yello fs-35"></span>
                <h4 class="text-gray fs-20">الطباعة</h4>
                <p class="g2 text-center fs-1">
                  تتميز مطبوعتنا بالدقة والكفاءة والمهنية وتأتي متوافقة مع أحدث تقنيات
                  وأساليب الطباعة الحديثة
                </p>
              </div>
            </b-col>
            <b-col class="my-3" data-aos-duration="1000" data-aos="fade-up-left">
              <div
                class="shadow px-3 py-3 rounded d-flex flex-column justify-conent-center align-items-center serv-card"
              >
                <span class="mdi mdi-bookshelf text-yello fs-35"></span>
                <h4 class="text-gray fs-20 text-center">التدقيق اللغوي وإنشاء المحتوى</h4>
                <p class="g2 text-center fs-1">
                  وذلك لدعم ذوي الأفكار المبدعة ومساعدتهم لإطلاق فكرتهم وتكميلها لترى
                  النور
                </p>
              </div>
            </b-col>
            <b-col class="my-3" data-aos-duration="1000" data-aos="fade-up-right">
              <div
                class="shadow px-3 py-3 rounded d-flex flex-column justify-conent-center align-items-center serv-card"
              >
                <span class="mdi mdi-book-open-variant text-yello fs-35"></span>
                <h4 class="text-gray fs-20">التوزيع</h4>
                <p class="g2 text-center fs-1">
                  تسعى مؤسسة العلم نور إلى أن تصل لكل منزل وتوسيع انتشارها إلى أنحاء
                  العالم لتكون قريبة منكم أينما كنتم
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import aos from "aos";
export default {
  created() {
    aos.init();
  },
};
</script>

<style lang="scss">
.servs {
  p {
    font-size: 12px !important;
  }
  flex-wrap: wrap;
}
.serv-card {
  transition: 0.5s;
  border-radius: 20px !important;
  background-color: rgba(255, 255, 255, 0.699);
  backdrop-filter: blur(5px);
  min-height: 230px;
  &::after {
    content: "";
    transition: 0.5s ease;
    width: 10%;
  }

  &:hover {
    transform: scale(1.1, 1.1);
    &::after {
      top: 97%;
      width: 90%;
      height: 5px;
      border-radius: 25px;
      background-color: #ffce00;
      position: absolute;
    }
  }
}
</style>
